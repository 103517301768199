import { IHistoricalPd, IHistoricalPdDriver, IPdDriversChartData } from '../../../interfaces/company-data.interface';
import { EdfxColors } from '../../../utils/edfx-style';
import { Utils } from '../../../utils/utils';

export abstract class EdfxRiskDriversDonutChartDataBuilder {
  public static computeRiskDriverContributionData(pdDriversChartData: IPdDriversChartData) {
    if (!(Array.isArray(pdDriversChartData?.contributions) && pdDriversChartData.contributions.length)) {
      return [];
    }

    const newData: { name: string; y: number; color: string }[] = [];
    const latestContributions: IHistoricalPdDriver = Utils.findLatestObjByDate(pdDriversChartData.contributions);

    latestContributions?.drivers.forEach(point => {
      if (Math.abs(point.value) < 0.01) {
        return;
      } else {
        newData.push({
          name: point.name.replace(/([A-Z])/g, ' $1').trim(),
          y: +Math.abs(point.value).toFixed(2),
          color: point.value >= 0 ? EdfxColors.red : EdfxColors.green
        });
      }
    });

    return newData;
  }

  public static computeRiskDriverPdData(historicalPdDrivers: IPdDriversChartData): IHistoricalPd {
    const historicalPdDriversLatestPd: IHistoricalPd = Utils.findLatestObjByDate(historicalPdDrivers.pds);

    return {
      date: historicalPdDriversLatestPd.date,
      pd: historicalPdDriversLatestPd.pd
    };
  }
}
