export const enum EdfxColors {
  // GRAY
  lightGray = '#FAF9F8',
  lightGray2 = '#F6F6F6',
  lightGray3 = '#F0F0F0',
  lightGray4 = '#D6D6D6',
  gray = '#a0a0a0',
  gray2 = '#898989',
  gray3 = '#7e7e7e',
  gray4 = '#3D3D3D',
  grayTransparent = 'rgba(160, 160, 160, 0.05)',

  // BLACK
  black = '#000000',

  // BLUE
  primaryBlue = '#0cb2e5',
  robinBlue = '#0089ed',
  darkBlue = '#0C279A',

  //GREEN
  green = '#83b562',
  darkGreen = '#119546',

  //ORANGE
  orange = '#ff8b0f',

  //PINK/VIOLET
  magenta = '#d60b7f',
  pink = '#f34398',
  redViolet = '#b5179e',
  purple = '#814e99',

  //RED
  red = '#d65040',
  darkRed = '#b42e1e',

  //TEAL
  teal = '#17abb5',
  tealTransparent = 'rgba(23, 171, 181, 0.1)',
  darkTeal = '#12868d',

  //WHITE
  white = '#ffffff',

  //YELLOW
  yellow = '#f5dc52',
  darkYellow = '#dbbf1d',
  yellowOrange = '#f2c022'
}

export const enum EdfxRiskColors {
  severe = EdfxColors.red,
  high = EdfxColors.orange,
  medium = EdfxColors.yellow,
  low = EdfxColors.green,
  inactive = EdfxColors.lightGray3
}

export const edfxTypographyRobotoB3 = { fontFamily: 'Roboto', fontSize: '14px', fontWeight: '400' };
