import { Injectable } from '@angular/core';
import { Canvg } from 'canvg';
import * as FileSaver from 'file-saver';
import * as HighChart from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  public getSVG(
    charts: HighChart.Chart[],
    options: HighChart.Options,
    scale: boolean,
    title: string,
    showTitle: boolean,
    callback: (...values: any) => void
  ) {
    let svg: any;
    if (charts.length > 0) {
      const chart = charts[0];
      const exportOptions = {
        exporting: {
          sourceHeight: 720,
          sourceWidth: 1020
        },
        title: {
          text: showTitle ? title : '',
          style: {
            display: 'unset'
          }
        },
        ...chart.options.exporting.chartOptions
      };
      svg = chart.getSVG(exportOptions);
    }
    return callback(svg);
  }

  public print(charts: HighChart.Chart[], options: HighChart.Options, title: string, showTitle: boolean) {
    this.exportCharts(charts, options, 'PRINT', title, showTitle);
  }

  public exportAsImg(charts: HighChart.Chart[], options: HighChart.Options, title: string, showTitle: boolean) {
    this.exportCharts(charts, options, 'IMG', title, showTitle);
  }

  public exportAsPDF(charts: HighChart.Chart[], options: HighChart.Options, title: string, showTitle: boolean) {
    this.exportCharts(charts, options, 'PDF', title, showTitle);
  }

  public exportAsCSV(charts: HighChart.Chart[], options: HighChart.Options, title: string, showTitle: boolean) {
    this.exportCharts(charts, options, 'CSV', title, showTitle);
  }

  public exportCharts(
    charts: HighChart.Chart[],
    options: HighChart.Options,
    type: 'PRINT' | 'PDF' | 'IMG' | 'CSV',
    title: string,
    showTitle = false
  ) {
    options = HighChart.merge(HighChart.getOptions().exporting, options);
    if (type === 'CSV' && Array.isArray(charts) && charts.length > 0) {
      const blob = new Blob(
        charts.map((chart: HighChart.Chart) => `${chart.getCSV()}\n\n`),
        { type: 'text/plain;charset=utf-8' }
      );
      FileSaver.saveAs(blob, title + '.csv');
      return;
    }
    // Get SVG asynchronously and then download the resulting SVG
    const scale = type !== 'PRINT';
    this.getSVG(charts, options, scale, title, showTitle, svg => {
      const canvas = document.createElement('canvas');
      switch (type) {
        case 'PRINT':
          const windowF = window.open();
          if (windowF) {
            windowF.document.write('<img src="' + canvas.toDataURL() + '"/>');
            setTimeout(() => {
              windowF.print();
            }, 500);
            windowF.onafterprint = windowF.close;
          }
          break;
        case 'IMG':
          const ctx = canvas.getContext('2d');
          const v = Canvg.fromString(ctx, svg);
          v.start();
          canvas.toBlob(blob => {
            FileSaver.saveAs(blob || '', title + '.png');
          });
          break;
      }
    });
  }
}
