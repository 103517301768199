<highcharts-chart
  (chartInstance)="setChart($event)"
  [(update)]="updateFlag"
  [Highcharts]="highcharts"
  [options]="options"
  [style.margin-top]="marginTop"
  [style.width]="width"
  [style.height]="height"
  style="display: block"
  id="{{ 'chart_' + name }}"
  [callbackFunction]="callbackFunction"
></highcharts-chart>
