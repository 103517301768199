import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HTTP_CORRELATION_ID, HTTP_HEADERS_AUTHORIZATION, HTTP_SESSION_ID } from 'interfaces/constants';
import moment from 'moment';
import { BehaviorSubject, Observable, catchError, from, map } from 'rxjs';
import { SessionService } from '../../services/session.service';
import {
  ICompanyModel,
  ICompanyProfile,
  IHistoricalPd,
  IHistoricalPdDriver,
  IHistoricalPdDrivers,
  IPDHistoricalInputPayload,
  IPdDriverDateValuePair,
  IPdDriversChartData,
  IPdDriversData,
  IPdDriversInput,
  ITermStructure,
  ITermStructureAnnualized,
  ITermStructureCumulative,
  ITermStructureDataWithIR,
  ITermStructureForward
} from '../interfaces/company-data.interface';
import { IEntitlement } from '../interfaces/entitlement.interface';
import { IEntitySearchResponse, IMmsModel } from '../interfaces/mms-model.interface';
import { Utils } from '../utils/utils';

interface IHttpGetOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  observe?: 'body';
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

interface IHttpGetJsonOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  observe?: 'body';
  reportProgress?: boolean;
  responseType?: 'json' | 'text';
  withCredentials?: boolean;
}

interface IHttpGetArrayBufferOptions {
  observe?: 'body';
  responseType: 'arraybuffer' | 'blob';
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  withCredentials?: boolean;
}

interface IHttpPostOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  transformRequest?: any;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpPatchOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpPutOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpDeleteOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected readonly baseUrl!: string;
  protected readonly sessionId!: string;
  private monthlyPdDrivers = new BehaviorSubject<IPdDriversData[]>([]);
  token!: string;

  constructor(protected http: HttpClient, protected sessionService: SessionService) {
    this.baseUrl = '';
    this.sessionId = sessionService.sessionId ?? '';
  }

  public fetchJson<T>(path: string): Observable<T> {
    return from(fetch(path).then(res => res.json()));
  }

  public setToken(token: string) {
    this.token = token;
  }

  public get(path: string, options: IHttpGetArrayBufferOptions): Observable<ArrayBuffer>;
  public get<T>(path: string, options?: IHttpGetJsonOptions): Observable<T>;
  public get(path: string, options: IHttpGetOptions = {}): Observable<any> {
    return this.http.get(this.baseUrl + path, this.getOptions(options) as any);
  }
  public post<T>(path: string, body: any | null, options?: IHttpPostOptions): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body, this.getOptions(options) as IHttpPostOptions);
  }

  private getOptions(options: any): any {
    const headers: { [p: string]: string } = {};
    headers[HTTP_SESSION_ID] = this.sessionId;
    headers[HTTP_CORRELATION_ID] = Utils.generateUUID();
    headers[HTTP_HEADERS_AUTHORIZATION] = 'Bearer ' + this.token;
    if (!options) {
      options = {};
    }
    options = { ...options, headers: { ...options.headers, ...headers } };
    return options;
  }

  public getModel(entityId: string, analysisDate: string): Observable<ICompanyModel> {
    return this.get<ICompanyModel>(`${environment.endPointConfig.apiUrl}/entity/${entityId}/model?analysisDate=${analysisDate}`);
  }

  public getCompanyProfile(companyId: string): Observable<ICompanyProfile> {
    return this.get<ICompanyProfile>(`${environment.endPointConfig.apiUrl}/entity/${companyId}/profile`);
  }

  public getMonthlyHistoricalPdDrivers(input?: IPDHistoricalInputPayload): Observable<IHistoricalPdDrivers | IPdDriversChartData> {
    const id = input?.entityId;
    const startDateQuery = input?.startDate ? `&startDate=${formatDate(input.startDate, 'yyyy-MM-dd', 'en-US')}` : '';
    const endDateQuery = input?.endDate ? `&endDate=${formatDate(input.endDate, 'yyyy-MM-dd', 'en-US')}` : '';
    const frequencyQuery = input?.frequency ? `&frequency=${input.frequency}` : '';
    const fsoQuery = `&fso=${input?.fso}`;
    const pdDriversInput = {
      startDate: input?.startDate,
      endDate: input?.endDate,
      entityId: input?.entityId,
      frequency: input?.frequency,
      fso: input?.fso
    };

    return this.get<IHistoricalPdDrivers>(
      `${environment.endPointConfig.apiUrl}/pdHistorical?id=${id}${startDateQuery}${endDateQuery}${frequencyQuery}${fsoQuery}`
    ).pipe(
      map(data => {
        const pdDriverData: IPdDriversData = { inputs: input, loading: false, data, error: null };
        const currentData = this.monthlyPdDrivers.getValue();
        const targetPdDriverIndex = this.findIndex(currentData, pdDriversInput);
        if (targetPdDriverIndex === -1) {
          currentData.push(pdDriverData);
        } else {
          currentData[targetPdDriverIndex] = pdDriverData;
        }
        this.monthlyPdDrivers.next(currentData);
        const filteredPDDrivers = this.filterPDDrivers(input?.startDate, input?.endDate, input?.entityId, input?.fso, currentData);

        return filteredPDDrivers;
      }),
      catchError(err => {
        const currentData = this.monthlyPdDrivers.getValue();
        const targetPdDriverIndex = this.findIndex(currentData, pdDriversInput);
        if (targetPdDriverIndex !== -1) {
          currentData[targetPdDriverIndex].error = err;
          this.monthlyPdDrivers.next(currentData);
        }
        throw err;
      })
    );
  }

  private filterPDDrivers(startDate?: string, endDate?: string, entityId?: string, fso?: boolean, pdDrivers?: IPdDriversData[]) {
    let driversData: IPdDriversData | null = null;

    for (const data of pdDrivers || []) {
      const isWithinDateRange = moment(startDate).isSameOrAfter(data.inputs?.startDate) &&
        moment(endDate).isSameOrBefore(data.inputs?.endDate);
      const isFsoMatch = data.inputs?.fso === fso;
      const isEntityIdMatch = data.inputs.entityId === entityId;

      if (isWithinDateRange && isFsoMatch && isEntityIdMatch) {
        driversData = data;
        break;
      } else if (isWithinDateRange && isFsoMatch) {
        driversData = data;
      }
    }

    return this.buildPDDriversChartData(driversData);
  };

  private buildPDDriversChartData(driversData?: IPdDriversData) {
    if (!driversData || Utils.isNullOrUndefined(driversData.data)) {
      return null;
    }

    const drivers: IPdDriversChartData = {
      pds: this.convertPDsToPercents(driversData.data?.pds),
      contributions: this.convertDriversToPercents(driversData.data?.contributions),
      ratios: driversData.data?.ratios,
      percentiles: this.convertDriversToPercents(driversData.data?.percentiles),
      assetVolatilities: this.convertDriversToPointNumbers(driversData.data?.assetVolatilities),
      marketLeverages: this.convertDriversToPointNumbers(driversData.data?.marketLeverages),
      marketValueOfAssets: this.convertDriversToPointNumbers(driversData.data?.marketValueOfAssets),
      defaultPoints: this.convertDriversToPointNumbers(driversData.data?.defaultPoints),
      companyPds: this.convertPDToPointNumbers(this.convertPDsToPercents(driversData.data?.pds)),
      startDate: driversData.inputs?.startDate,
      endDate: driversData.inputs?.endDate,
      entityId: driversData.inputs.entityId,
      frequency: driversData.inputs.frequency,
      fso: driversData.inputs.fso,
      edfDrivers: driversData.data?.edfDrivers,
      termStructure: this.convertTermStructureToPercentages(driversData.data?.termStructure)
    };

    return drivers;
  }

  private convertTermStructureToPercentages = (termStructure: ITermStructure) => {
    if (Utils.isNullOrUndefined(termStructure) || Object.keys(termStructure).length === 0) {
      return null;
    }

    const forward: ITermStructureForward = termStructure.forward;
    const forwardData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(forward.forward1Y.pd),
        impliedRating: forward.forward1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward2Y.pd),
        impliedRating: forward.forward2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward3Y.pd),
        impliedRating: forward.forward3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward4Y.pd),
        impliedRating: forward.forward4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward5Y.pd),
        impliedRating: forward.forward5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward6Y.pd),
        impliedRating: forward.forward6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward7Y.pd),
        impliedRating: forward.forward7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward8Y.pd),
        impliedRating: forward.forward8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward9Y.pd),
        impliedRating: forward.forward9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward10Y.pd),
        impliedRating: forward.forward10Y.impliedRating
      }
    ];

    const annualized: ITermStructureAnnualized = termStructure.annualized;
    const annualizedData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(annualized.annualized1Y.pd),
        impliedRating: annualized.annualized1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized2Y.pd),
        impliedRating: annualized.annualized2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized3Y.pd),
        impliedRating: annualized.annualized3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized4Y.pd),
        impliedRating: annualized.annualized4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized5Y.pd),
        impliedRating: annualized.annualized5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized6Y.pd),
        impliedRating: annualized.annualized6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized7Y.pd),
        impliedRating: annualized.annualized7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized8Y.pd),
        impliedRating: annualized.annualized8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized9Y.pd),
        impliedRating: annualized.annualized9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized10Y.pd),
        impliedRating: annualized.annualized10Y.impliedRating
      }
    ];

    const cumulative: ITermStructureCumulative = termStructure.cumulative;
    const cumulativeData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(cumulative.cumulative1Y.pd),
        impliedRating: cumulative.cumulative1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative2Y.pd),
        impliedRating: cumulative.cumulative2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative3Y.pd),
        impliedRating: cumulative.cumulative3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative4Y.pd),
        impliedRating: cumulative.cumulative4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative5Y.pd),
        impliedRating: cumulative.cumulative5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative6Y.pd),
        impliedRating: cumulative.cumulative6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative7Y.pd),
        impliedRating: cumulative.cumulative7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative8Y.pd),
        impliedRating: cumulative.cumulative8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative9Y.pd),
        impliedRating: cumulative.cumulative9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative10Y.pd),
        impliedRating: cumulative.cumulative10Y.impliedRating
      }
    ];

    return {
      forward: forwardData,
      annualized: annualizedData,
      cumulative: cumulativeData
    };
  };

  private convertPDsToPercents(pds: IHistoricalPd[]): IHistoricalPd[] {
    return pds?.map(pd => ({
      ...pd,
      pd: pd?.pd && !isNaN(pd.pd) ? pd?.pd * 100 : pd?.pd
    }));
  }

  private convertDriversToPercents = (drivers: IHistoricalPdDriver[]) =>
    drivers?.map(driver => ({
      ...driver,
      drivers: driver?.drivers?.map(pdDriver => ({
        ...pdDriver,
        value: pdDriver?.value && !isNaN(pdDriver?.value) ? pdDriver?.value * 100 : pdDriver?.value
      }))
    }));

  private convertPDToPointNumbers = (pds: IHistoricalPd[]) => pds?.map(ir => ({ value: ir.pd, date: ir.date }));

  private convertDriversToPointNumbers = (drivers: IPdDriverDateValuePair[]) =>
    drivers?.map(ir => ({
      value: ir.value,
      date: ir.date
    }));

  private findIndex(pdDriversData: IPdDriversData[], input: IPdDriversInput): number {
    return pdDriversData.findIndex(
      driver =>
        driver.inputs?.startDate === input.startDate &&
        driver.inputs?.endDate === input.endDate &&
        driver.inputs?.entityId === input.entityId &&
        driver.inputs?.frequency === input.frequency &&
        driver.inputs?.fso === input.fso
    );
  }

  public getMmsModel(
    modelId: string,
    includeDocumentation: boolean,
    includeDrivers: boolean,
    includeInputs: boolean
  ): Observable<IMmsModel> {
    const includeDocumentationQuery = `includeDocumentation=${includeDocumentation}`;
    const includeDriversQuery = `&includeDrivers=${includeDrivers}`;
    const includeInputsQuery = `&includeInputs=${includeInputs}`;

    /* eslint-disable */
    return this.get<IMmsModel>(
      `${environment.endPointConfig.mmsApiUrl}/models/${modelId}?${includeDocumentationQuery}${includeDriversQuery}${includeInputsQuery}`
    );
  }

  public getEntityId(query: string, isCustom: boolean): Observable<IEntitySearchResponse> {
    const payload = isCustom ? { queries: [{ customEntityIdentifier: query }] } : { queries: [{ entityId: query }] };
    return this.post(`${environment.endPointConfig.entityUrl}/mapping`, payload);
  }

  public getEntitlements(payload: string[]): Observable<IEntitlement[]> {
    return this.post<IEntitlement[]>(`${environment.endPointConfig.apiUrl}/entitlements`, payload);
  }
}
