<div *ngIf="showContent()" class="container mb-4">
  <div>
    <div class="card-title">{{localizationData.COMPANY.DRIVERS.RISK_DRIVERS_DONUT_CHART.TITLE}}</div>
    <ng-container *ngIf="hasData(); else showMessage">
      <edfx-analytic-chart
        *ngIf="hasData() && !displayInactiveMessage"
        [options]="options"
        (chartInstance)="setChart($event)"
        [callbackFunction]="onChartLoaded"
        [loading]="loadingChart"
      ></edfx-analytic-chart>
    </ng-container>
    <div
      *ngIf="displayInactiveMessage && !loadingChart"
      class="d-flex flex-row align-items-center justify-content-center full-height full-width"
    >
      <span class="override-message"> {{localizationData.CHART.OVERWRITE_MESSAGE}} </span>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <div class="spinner" *ngIf="loadingChart && hasEntitlement"></div>
</div>
<ng-template #showMessage>
  <div
    *ngIf="!hasData() && !displayInactiveMessage && !loadingChart && !invalidEntityId"
    class="d-flex flex-row align-items-center justify-content-center full-height full-width"
  >
    <span class="override-message"> {{localizationData.GLOBAL.DATA.NO_DATA_TO_DISPLAY}} </span>
  </div>
</ng-template>

<p class="risk-driver-unavailable" *ngIf="!tokenInvalid && !loadingChart && hasEntitlement && riskDriversUnavailable">
  {{localizationData.TYPE.PUBLIC_OR_BENCHMARK }}
</p>

<div class="token-invalid-message" *ngIf="tokenInvalid && !requestInProgress">{{ localizationData.TOKEN.INVALID_MESSAGE }}</div>
<ng-container *ngIf="!hasEntitlement && !requestInProgress">
  <p class="token-invalid-message">User does not have EDF-X access. Please contact your Moody's representative.</p>
</ng-container>
<ng-container *ngIf="invalidEntityId">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>
