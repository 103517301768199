import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EdfxRiskDriversDonutChartComponent } from './components/views/edfx-risk-drivers-donut-chart/edfx-risk-drivers-donut-chart.component';
import { EdfxRiskDriversDonutChartModule } from './components/views/edfx-risk-drivers-donut-chart/edfx-risk-drivers-donut-chart.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, EdfxRiskDriversDonutChartModule],
  providers: [],
  bootstrap: []
})
export class AppModule {
  constructor(injector: Injector) {
    const riskDriverElement = createCustomElement(EdfxRiskDriversDonutChartComponent, {
      injector
    });
    customElements.define('risk-drivers-donut-chart', riskDriverElement);
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngDoBootstrap() {}
}
