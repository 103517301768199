import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartCallbackFunction } from 'highcharts';
import HC_dataExporting from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_no_data from 'highcharts/modules/no-data-to-display';
import HC_stock from 'highcharts/modules/stock';
import { Subject } from 'rxjs';
import { ChartType } from '../../interfaces/chart.interface';
import { ChartService } from '../../services/chart.service';
import { ChartUtils } from '../../utils/chart.utils';

HC_stock(Highcharts);
HC_exporting(Highcharts);
HC_dataExporting(Highcharts);
HC_no_data(Highcharts);

@Component({
  selector: 'edfx-analytic-chart',
  templateUrl: './analytic-chart.component.html',
  styleUrls: ['./analytic-chart.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class AnalyticChartComponent implements OnDestroy {
  @Input() public width = '100%';
  @Input() public height: string;
  @Input() public marginTop = '12px';
  @Input() public type: ChartType;
  @Input() highcharts: typeof Highcharts = Highcharts;
  @Input() name: string;
  @Input() public callbackFunction: ChartCallbackFunction;
  @Output() public chartInstance: EventEmitter<Highcharts.Chart> = new EventEmitter<Highcharts.Chart>();
  chart: Highcharts.Chart;
  updateFlag = true;
  private pOptions: Highcharts.Options = {
    series: []
  };
  private destroy$: Subject<boolean> = new Subject();
  private pLoading = false;

  constructor(private chartService: ChartService) {
    const defaultOptions: Highcharts.Options = {
      ...ChartUtils.defaultChartOptionsWithoutExcel
    };
    this.options = defaultOptions;
  }

  @Input() set options(options: Highcharts.Options) {
    this.pOptions = {
      ...this.options,
      ...options
    };
  }

  get options(): Highcharts.Options {
    return this.pOptions;
  }

  /**
   * Loading management
   *
   * @param value boolean
   */
  @Input()
  public set loading(value: boolean) {
    this.pLoading = value;

    if (!this.chart) {
      return;
    }
    this.showOrHideSpinner();
  }

  public get loading(): boolean {
    return this.pLoading;
  }

  print(): void {
    this.chartService.print([this.chart], {}, this.chart.options.title?.text || '', false);
  }

  exportAsImg(): void {
    this.chartService.exportAsImg([this.chart], {}, this.chart.options.title?.text || '', true);
  }

  exportAsPDF(): void {
    this.chartService.exportAsPDF([this.chart], {}, this.chart.options.title?.text || '', false);
  }

  exportAsCSV(): void {
    this.chart.downloadCSV();
  }

  /**
   * reference the chart instance
   *
   * @param chart the chart
   * @param index the index
   */
  public setChart(chart: Highcharts.Chart): void {
    this.chart = chart;
    this.chartInstance.emit(this.chart);
    this.showOrHideSpinner();
  }

  showOrHideSpinner() {
    if (this.pLoading) {
      this.chart?.showLoading('Loading ...');
    } else {
      this.chart.hideLoading();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
