import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { AnalyticChartComponent } from './analytic-chart.component';

@NgModule({
  imports: [HighchartsChartModule],
  declarations: [AnalyticChartComponent],
  exports: [AnalyticChartComponent],
  schemas: []
})
export class AnalyticChartModule {}
