import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { AnalyticChartModule } from '../../analytic-chart/analytic-chart.module';
import { EdfxRiskDriversDonutChartComponent } from './edfx-risk-drivers-donut-chart.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [EdfxRiskDriversDonutChartComponent],
  exports: [EdfxRiskDriversDonutChartComponent],
  imports: [AnalyticChartModule, CommonModule, TranslocoRootModule,
    TranslocoModule, FlexLayoutModule, BrowserAnimationsModule, NoopAnimationsModule
  ]
})
export class EdfxRiskDriversDonutChartModule { }
