import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as Highcharts from 'highcharts';
import { Entitlements, FREQUENCY_MONTHLY, INSOLVENT_BANKRUPT, MOMENT_FORMATS } from 'interfaces/constants';
import { Debounce } from 'lodash-decorators';
import moment from 'moment';
import { IEntitySearchResponse } from 'projects/company-profile/src/app/types';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  ICompanyModel,
  ICompanyProfile,
  IHistoricalPd,
  IPDHistoricalInputPayload,
  IPdDriversChartData,
  ModelId
} from '../../../interfaces/company-data.interface';
import { IEntitlement } from '../../../interfaces/entitlement.interface';
import { IMmsModel } from '../../../interfaces/mms-model.interface';
import { IRiskDriversCustomData } from '../../../interfaces/risk-driver-custom-data.interface';
import { ApiService } from '../../../services/api.service';
import { ChartService } from '../../../services/chart.service';
import { ChartUtils } from '../../../utils/chart.utils';
import { DateUtils } from '../../../utils/date.utils';
import { EdfxColors, edfxTypographyRobotoB3 } from '../../../utils/edfx-style';
import { Utils } from '../../../utils/utils';
import { EdfxRiskDriversDonutChartDataBuilder } from './edfx-risk-drivers-donut-chart.data-builder';
@Component({
  selector: 'edfx-risk-drivers-donut-chart',
  templateUrl: './edfx-risk-drivers-donut-chart.component.html',
  styleUrls: ['./edfx-risk-drivers-donut-chart.component.scss']
})
export class EdfxRiskDriversDonutChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public title: string;
  @Input() public displayTitle = false;
  @Input() public showActions = false;
  @Input() public titleIcon: string | null = null;
  @Input() token!: string;
  @Input() inputData!: string | IRiskDriversCustomData;
  riskDriversCustomData: IRiskDriversCustomData;
  public hasMmsModel: boolean | undefined;
  tokenInvalid = false;
  requestInProgress = false;
  mmsModel$?: Observable<IMmsModel>;
  model: IMmsModel = null;

  public companyInfoLoading = true;
  public isPrivateModel = false;
  public isPublicModel = false;
  public loadingChart = true;
  public displayInactiveMessage = false;
  public chart: Highcharts.Chart;
  public options: Highcharts.Options;
  public defaultOptions: Highcharts.Options = {
    chart: {
      style: edfxTypographyRobotoB3,
      type: 'pie',
      events: {
        load() {
          this.showLoading();
        }
      }
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'middle',
      y: 1,
      style: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '1.7vw'
      },
      useHTML: true
    },
    exporting: {
      chartOptions: {
        title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: 1,
          style: {
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            fontSize: '1.7vw'
          },
          useHTML: true
        }
      },
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    plotOptions: {
      pie: {
        size: 250,
        shadow: false,
        center: ['50%', '50%'],
        innerSize: '80%',
        dataLabels: {
          enabled: true,
          style: {
            textOverflow: 'clip'
          },
          formatter() {
            return `
            <div style="font-weight: 300">
            ${this.point.name}:
            </div>
            <br>
            ${this.point.color === EdfxColors.green ? '- ' : ''}${this.point.y.toFixed(2)}%
            `;
          },
          connectorShape: 'crookedLine',
          connectorColor: '#A0A0A0'
        }
      },
      series: {
        states: {
          hover: {
            halo: {
              size: 0
            }
          }
        }
      }
    },
    tooltip: {
      formatter() {
        return `
            <div style="font-weight: 300">
            ${this.point.name}:
            </div>
            <br>
            ${this.point.color === EdfxColors.green ? '- ' : ''}${this.point.y}%
            `;
      },
      valueSuffix: '%'
    }
  };

  private pData: IPdDriversChartData;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public companyModel: ICompanyModel;
  public companyName: string;
  public isFinancialModel = true;
  public historicalDriverInputs: IPDHistoricalInputPayload;
  public pdDriversChartData: IPdDriversChartData;

  private primaryModelId = '';
  hasEntitlement = false;
  todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  entityId: string;
  analysisDate: string;
  riskDriversUnavailable: boolean;
  invalidEntityId = false;

  constructor(private chartService: ChartService, private translateService: TranslocoService, private apiService: ApiService) {
    // Only for dev testing, comment out for building the web element!
    /*this.riskDriversCustomData = {
      entityId: 'US69403NU'
    };
    this.token = // eslint-disable-next-line max-len
    '';
    this.inputData = JSON.stringify(this.riskDriversCustomData);
    */
  }

  get data(): IPdDriversChartData {
    return this.pData;
  }

  @Input() set data(value: IPdDriversChartData) {
    this.pData = value;
  }

  public hasData(): boolean {
    return this.data && Array.isArray(this.data.contributions) && this.data.contributions.length > 0;
  }

  @Input()
  @Debounce(500)
  public set loading(load: boolean) {
    this.loadingChart = load;
    if (!this.loadingChart && this.hasData()) {
      this.updateData(this.data);
    }
  }

  public setChart(chartInstance: Highcharts.Chart) {
    this.chart = chartInstance;
    this.updateData(this.data);
  }

  ngOnInit(): void {
    if (!Utils.isNullOrUndefined(this.inputData) && !Utils.isNullOrUndefined(this.token)) {
      this.getRiskDriversCustomDataCustomDataFromInput();
      this.initializeComponent(this.riskDriversCustomData);
    }
  }

  getRiskDriversCustomDataCustomDataFromInput(){
    if(typeof this.inputData === 'string'){
      this.riskDriversCustomData = JSON.parse(this.inputData) as IRiskDriversCustomData;
    }
    else if (typeof this.inputData === 'object'){
      this.riskDriversCustomData = this.inputData;
    }
  }

  initializeComponent(riskDriversCustomData: IRiskDriversCustomData): void {
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    const entityId = riskDriversCustomData?.entityId;
    this.loadingChart = true;

    if (!Utils.isNullOrUndefined(this.token)) {
      this.apiService.setToken(this.token);
    }
    if (!entityId) {
      return;
    }
    this.invalidEntityId = false;
    this.tokenInvalid = false;
    this.requestInProgress = true;
    this.apiService
      .getEntitlements(entitlement_list)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loadingChart = false;
          }
          this.requestInProgress = false;
          this.hasEntitlement = true;
          return of();
        })
      )
      .subscribe(entitlements => this.onEntitlementsReceived(entitlements, riskDriversCustomData));
  }

  onEntitlementsReceived(entitlements: IEntitlement[], riskDriversCustomData: IRiskDriversCustomData) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.requestInProgress = false;
      return;
    }
    this.entityId = riskDriversCustomData?.entityId;
    this.analysisDate = !!riskDriversCustomData?.asOfDate ? riskDriversCustomData?.asOfDate : this.todaysDate;
    this.getEntityId();
  }

  getEntityId(): void {
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(this.entityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loadingChart = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived(this.entityId, this.analysisDate);
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loadingChart = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }

        if (entityIdResult.entities.length === 0) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived(this.entityId, this.analysisDate);
      });
  }

  showContent(): boolean {
    return (
      !this.loadingChart &&
      this.isPrivateModel &&
      !this.displayInactiveMessage &&
      !this.riskDriversUnavailable &&
      !this.requestInProgress &&
      !this.tokenInvalid &&
      this.hasEntitlement &&
      !this.invalidEntityId
    );
  }

  onEntityIdReceived(entityId: string, analysisDate: string) {
    this.requestInProgress = true;
    this.tokenInvalid = false;
    combineLatest([this.apiService.getModel(entityId, analysisDate), this.apiService.getCompanyProfile(entityId)])
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loadingChart = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe(([model, profile]) => {
        this.onEntityInfoRecieved(model, profile);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.token = changes['token'].currentValue;
    }

    if (changes['inputData'] && !Utils.isNullOrUndefined(changes['inputData'].currentValue)) {
      this.inputData = changes['inputData'].currentValue;
      this.chart = null;
      this.options = this.defaultOptions;
      this.getRiskDriversCustomDataCustomDataFromInput();
      this.initializeComponent(this.riskDriversCustomData);
    }
  }

  private onEntityInfoRecieved(model: ICompanyModel, profile: ICompanyProfile): void {
    if (!model || !profile) {
      this.companyInfoLoading = true;
      return;
    }

    this.requestInProgress = false;
    this.companyInfoLoading = false;
    this.companyModel = model;
    this.companyName = profile.name || '';

    const modelId = this.companyModel.modelId;
    this.primaryModelId = modelId ? modelId.split('+')[0] : '';
    const isBenchmarkModel = this.primaryModelId.toLowerCase().includes(ModelId.BENCHMARK);
    const isPaymentModel = this.primaryModelId.toLowerCase().includes(ModelId.PAYMENT);
    this.isFinancialModel = !isBenchmarkModel && !isPaymentModel;

    const numberOfYears = this.isFinancialModel ? 5 : 4;
    const latestKnownDate = DateUtils.getLatestDate(this.companyModel.latestKnownPdDate);
    const inputEndDate = latestKnownDate.format(MOMENT_FORMATS.payload);
    const inputStartDate = latestKnownDate.subtract(numberOfYears, 'years').format(MOMENT_FORMATS.payload);

    this.historicalDriverInputs = {
      entityId: this.companyModel.id,
      endDate: inputEndDate,
      startDate: inputStartDate,
      frequency: FREQUENCY_MONTHLY,
      fso: true
    };

    const primaryModelId = modelId ? modelId.split('+')[0] : '';
    this.isPublicModel = primaryModelId.toLowerCase().includes(ModelId.EDF9);
    this.isPrivateModel = !this.isPublicModel;
    this.riskDriversUnavailable = isBenchmarkModel || this.isPublicModel;

    this.displayInactiveMessage = ChartUtils.displayInactiveMessage(model.confidenceIndicator, INSOLVENT_BANKRUPT, this.isPublicModel);

    if (!this.riskDriversUnavailable) {
      this.subscribeToMonthlyPDHistoricalDataAndMmsData();
    } else {
      this.loadingChart = false;
    }
  }

  private processMmsModel(mmsModel: IMmsModel): void {
    this.model = mmsModel;
    this.hasMmsModel = true;
  }
  private subscribeToMonthlyPDHistoricalDataAndMmsData() {
    this.mmsModel$ = this.apiService.getMmsModel(this.primaryModelId, true, true, true);
    combineLatest([this.apiService.getMonthlyHistoricalPdDrivers(this.historicalDriverInputs), this.mmsModel$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([historicalPdDrivers, mmsModel]) => {
        if (!historicalPdDrivers || !mmsModel) {
          return;
        }
        // Check if historicalPdDrivers is of type IPdDriversChartData
        if (
          'startDate' in historicalPdDrivers &&
          'endDate' in historicalPdDrivers &&
          'fso' in historicalPdDrivers &&
          'frequency' in historicalPdDrivers &&
          'entityId' in historicalPdDrivers
        ) {
          this.processHistoricalPdDrivers(historicalPdDrivers);
        }
        this.processMmsModel(mmsModel);
      });
  }

  private processHistoricalPdDrivers(historicalPdDrivers: IPdDriversChartData): void {
    if (!historicalPdDrivers || !this.companyModel) {
      return;
    }

    this.pdDriversChartData = historicalPdDrivers;

    this.pdDriversChartData.lastKnownPd =
      this.companyModel?.latestKnownPdValue && !isNaN(this.companyModel.latestKnownPdValue)
        ? this.companyModel?.latestKnownPdValue * 100
        : this.companyModel?.latestKnownPdValue;
    this.pdDriversChartData.lastKnownPdDate = this.companyModel.latestKnownPdDate;

    this.pdDriversChartData.quantitativePd =
      this.companyModel?.latestKnownQuantitativePd && !isNaN(this.companyModel.latestKnownQuantitativePd)
        ? this.companyModel.latestKnownQuantitativePd * 100
        : this.companyModel?.latestKnownQuantitativePd;

    this.pData = this.pdDriversChartData;
    this.loadingChart = false;
    if (!this.loadingChart && this.hasData()) {
      this.updateData(this.data);
    }
  }

  public onChartLoaded() {}

  public updateData(data: IPdDriversChartData) {
    if (!this.chart) {
      return;
    }
    const contributionData = EdfxRiskDriversDonutChartDataBuilder.computeRiskDriverContributionData(data);
    const pdData = EdfxRiskDriversDonutChartDataBuilder.computeRiskDriverPdData(data);
    if (this.chart && this.chart?.series) {
      while (this.chart?.series?.length > 0) {
        this.chart?.series[0]?.remove(true);
      }
    }
    if (this.hasData()) {
      const seriesToAdd = {} as any;
      seriesToAdd.name = '';
      seriesToAdd.type = 'pie';
      seriesToAdd.data = contributionData;
      this.chart?.addSeries(seriesToAdd);
      this.chart?.reflow();
      setTimeout(() => {
        this.loadingChart = false;
      }, 0);
    }
    this.setChartTitle(pdData);
    this.setChartSubtitle(pdData);
    this.loadingChart = false;
  }

  public setChartTitle(pdData: IHistoricalPd) {
    const text = pdData?.pd && !isNaN(pdData?.pd) ? pdData?.pd.toFixed(2) + '%' : 'N/A';
    this.options.title.text = text;
    this.options.exporting.chartOptions.title.text = text;
    this.chart?.update(this.options, true);
  }

  public setChartSubtitle(pdData: IHistoricalPd) {
    const newPdDate = moment(pdData?.date).format(MOMENT_FORMATS.display.monthYearLabel);
    const text = 'TTC PD<br>(' + newPdDate + ')';
    this.chart?.setSubtitle({
      text,
      align: 'center',
      verticalAlign: 'middle',
      y: 25,
      style: {
        fontFamily: 'Roboto',
        fontSize: '.8vw',
        color: '000000'
      }
    });
  }

  public downloadAsPng() {
    this.chartService.exportAsImg([this.chart], {}, this.title || '', true);
  }

  public fullScreen() {
    this.chart;
    this.chart.fullscreen.open();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
